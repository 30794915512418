/* Global styles to reset margin, padding, and set box-sizing*/

@font-face {
  font-family: "SF Pro Display" !important;
  src: url("../public/sf-pro-display-cufonfonts/SFPRODISPLAYREGULAR.OTF")
    format("otf"); /* Path to the font file */
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  /* font-family: "SF Pro Display"; */
  /* font-family: "SanFranciscoProDisplay" !important; */
}

/* body {
  font-family: "SanFranciscoProDisplay" !important;
} */

body {
  font-family: "Poppins", monospace !important; /* Use Poppins and fallback to monospace if Poppins is not available */
}