body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: "hidden";
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: "SanFranciscoProDisplay" !important;
} */

code {
  font-family: "Poppins", monospace !important; /* Use Poppins and fallback to monospace if Poppins is not available */
}